<template>
	<main>
		<div class="app-heading pd:md">
			<div class="app-title" v-t="$route.params.genre ? `genre.${$route.params.genre}` : 'all_games'"></div>
			<hr>
		</div>

		<div v-if="isLoading" class="preloader"></div>

		<div class="pd:md" style="padding-top: 0">
			<component-games-list-grid :games="games"/>
		</div>
	</main>
</template>

<script>
import ComponentGamesListGrid from '@/components/component-games-list-grid'

export default {
	name: 'genre',
	components: {
		ComponentGamesListGrid
	},
	data () {
		return {
			isLoading: true,
			genre: '',
			games: []
		}
	},
	methods: {
		async init () {
			const payload = []

			const modelGenre = this.$route.params.genre ? 'get_content_all_genre' : 'get_content_all'
			const payloadGenre = {
				game: [
					{ [modelGenre]: [{ args: [{ genre: this.$route.params.genre }] }] }
				]
			}

			payload.push(payloadGenre)

			const responses = await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: payload
			})

			if (responses.game[modelGenre][0][0].code === 2001) {
				this.games = responses.game[modelGenre][0][0].data
				this.genre = this.$route.params.genre
			}

			this.isLoading = false
			window.scrollTo(0, 0)
		}
	},
	async created () {
		await this.init()
	},
	activated () {
		if (this.genre !== this.$route.params.genre) {
			this.init()
		}
	}
}
</script>

<style lang="scss" scoped>
main {
	background-color: $color-background;
	color: $color-foreground;

	.app-heading {
		.app-title {
			text-transform: none;
		}

		hr {
			margin-bottom: 0;
			color: $color-accent-pink;
			border-color: $color-accent-pink;
			border-width: .1em;
		}
	}
}
</style>
